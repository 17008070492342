
<div class="loader-wrapper" style="margin: auto" *ngIf="loaderService.loading$ | async">


  <div class="loader">
    <div class="m-t-30">
      <img
        src="../../../assets/images/loader-cube.gif"
        width="100"
        height="100"
        alt="loader"
      />
    </div>
    <p style="text-align: center;">Chargement...</p>
  </div>
</div>



